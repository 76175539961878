@font-face {
    font-family: nw-icons;
    /* stylelint-disable declaration-colon-newline-after, value-list-comma-newline-after */
    src: url("../css/fonts/nw-icons.eot"), url("../css/fonts/nw-icons.eot?#iefix") format("embedded-opentype"),
        url("../css/fonts/nw-icons.woff") format("woff"), url("../css/fonts/nw-icons.ttf") format("truetype"),
        url("../css/fonts/nw-icons.svg#nw-icons") format("svg");
    /* stylelint-enable declaration-colon-newline-after, value-list-comma-newline-after */
    font-weight: normal;
    font-style: normal;
}

.bolt-icon-wc.is-icon.smartcar-car-icon {
    height: 64px;
    width: 64px;
}
