@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;

/* BUTTONS */

.button-container {
    width: 100%;
    border-top: 1px solid #d6d6d6;
    padding: 20px 10px;
    background-color: Bolt.$palette-paleGray50;
    margin-top: Bolt.$space-lg;
}

.change-buttons-container {
    padding: 20px;
}

/* Override for Bolt Button */

.btn {
    font-size: 16px;
}

bolt-button {
    display: inherit;
}

.button {
    border: 0;
    cursor: pointer;
    min-width: 70px;
    padding: 10px 25px;
    text-decoration: none;
    text-align: center;
    overflow: visible;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    @include border-radius(4px);
    @include box-shadow(0, 1px, 1px, 0, rgba(0, 3, 51, 1));
}

.button-margin {
    margin-right: 15px;
}

.button-left-margin {
    margin-left: 15px;
}

.button-right {
    float: right;
}

.button-left {
    float: left;
}

.button-bottom {
    margin-top: 40px;
}

.bolt-button-wc.bolt-button-secondary.bolt-button.smartcar-bolt-button {
    background: inherit;
    border: 1px solid white;
    color: white;
}

@media only screen and (max-width: 610px) {
    .bolt-button-wc.bolt-button {
        width: 100%;
    }

    .bolt-button-wc--secondary.bolt-button {
        background: white;
    }
}

@media screen and (max-width: 650px) {
    .button {
        padding: 8px 10px;
    }
}

@media screen and (max-width: 550px) {
    .nw-button-container .bolt-button-wc + .bolt-button-wc {
        margin: 12px 0 0;
    }
}

@media screen and (max-width: 410px) {
    .button-spacing {
        margin-bottom: 10px;
    }

    .button {
        margin-bottom: 10px;
    }

    .button-margin {
        margin-right: 0;
    }

    .button-left-margin {
        margin-left: 0;
    }
}

/*Implement responsive classes for ADA*/

@media screen and (min-width: 768px) {
    .ada-display--desktop {
        display: inherit;
    }

    .ada-display--mobile {
        display: none;
    }
}
@media screen and (max-width: 768px) {
    .button-top {
        margin-top: Bolt.$space-sm;
    }

    .button-left-margin {
        margin-left: 0;
    }

    .button-left,
    .button-right {
        clear: both;
        float: none;
    }

    .ada-display--desktop {
        display: none;
    }

    .ada-display--mobile {
        display: inherit;
    }

    .button-margin {
        margin-right: 0;
    }
}

@media screen and (max-width: 600px) {
    .button-container {
        padding: 10px;
    }
}
